
@import './styles/global.scss';

body  {
  background-color: $background;
  max-width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}


.App {
  text-align: center;
}
