@import '../styles/global.scss';

main {
    height: 100%;
    overflow: hidden;
}
.img-container {
    width: 78%;
    display: flex;
    align-items: center;

    @include mobile {
        width: 100%;
        padding:0;
        
    }


    &__img {
        width: 100%;
    }

}

.main-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 3rem;
    padding-left: 3rem;
    position: relative;
    z-index: 1;


    @include mobile {
        padding-left: 0;
        
    }


    &__right {
        display: flex;
        align-items: center;
        
        width: 8rem;
        justify-content: space-between;
        cursor: pointer;
        padding-right: 4rem;

        @include mobile {
            padding-right: 0;
        }



        &__logo {
            width: 2rem;
            transform: rotateY(180deg);

        }
    }

    &__left {
        display: flex;
        align-items: center;
        width: 6rem;
        justify-content: space-between;
        cursor: pointer;
        padding-left: 4rem;

        @include mobile {
            padding-left: 0;
        }


    }

    &__logo {
        position: relative;
        display: block;
        animation: logoIn .45s ease-out .2s backwards;
        width: 2rem;
    }

    &__logo-txt {
        display: inline-block;
        font-family: Arial, Helvetica, sans-serif;
        letter-spacing: 0.2rem;
        color: #0f1b61;
        font-weight: 600;
        font-size: 12px;
        transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.3);

        &:hover {
            zoom: 1.2;
            opacity: 90%;
        }


    }

}



.home_container {
    display: flex;
    align-items: center;
    width: 100%;

    @include mobile() {
        flex-direction: column-reverse;
        margin-top: 2rem;
    }
    @include tablet() {
        flex-direction: column-reverse;
        margin-top: 2rem;
    }

    > section {
        width: 100%;
    }
}

.left_side {
    font-family: 'Bitter', sans-serif;
    text-align: left;
    padding: 8rem;
    transform: translateY(1%);
    color: $text;
    z-index: 9;

    @include mobile() {
        padding: 0;
        text-align: center;
        width: 100%;

    }

    &__title {
        font-size: 5rem;
        animation: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s 1 normal backwards running reveal;
        position: relative;
        line-height: 1.125;
        width: 100%;

        @include mobile() {
            font-size: 2.5rem;
            margin-bottom: 1rem;
            width: 100%;
            margin-top: 0.5rem;
        }


        scroll-margin-top: -40px;

    }

    &__subtitle {
        animation-delay: .75s;
        font-size: 2rem;
        max-width: 500px;
        margin-top: 30px;
        animation: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s 1 normal backwards running reveal;


        @include mobile() {
            font-size: 1rem;

        }

    }


}

.dot {
    color: $subtext
}

.right_side {
    animation: bakedAvatar 1.5s cubic-bezier(.215, .61, .355, 1) 1.25s backwards;


    &__bg {
        background: url('../styles/svg/diagonal-lines.svg');

        width: 50%;
        height: 15rem;
        position: absolute;
        bottom: 13.2rem;
        left: 33rem;
        z-index: 0;
    }

    // &__img-container {

    //     top: 12rem;
    //     z-index: 9;


    //     &__hair {
    //         width: 36rem;
    //         position: relative;
    //         right: 14rem;
    //         top: 1rem;

    //         @include mobile {
    //             width: 21rem;
    //             right: 1rem;
    //             top: -5rem;
    //         }
    //     }

    //     &__eyes {
    //         width: 5rem;
    //     }



    //     &__nose {
    //         width: 3rem;
    //         position: absolute;
    //         bottom: 18rem;
    //         right: 31rem;

    //         @include mobile {
    //             width: 2rem;
    //             bottom: 14rem;
    //             right: 11rem;
    //         }

    //     }

    //     &__sunrise {
    //         width: 6rem;
    //         position: absolute;
    //         bottom: 15rem;
    //         right: 28rem;

    //         @include mobile {
    //             width: 3rem;
    //             bottom: 12rem;
    //             right: 9rem;
    //         }

    //     }
    // }

    // &__eyes-container {
    //     display: flex;
    //     width: 12rem;
    //     justify-content: space-between;
    //     position: absolute;
    //     bottom: 20rem;
    //     right: 27rem;


    //     @include mobile {
    //         width: 5rem;
    //         bottom: 14rem;
    //         right: 12rem;
    //     }

    //     div {
    //         position: relative;

    //     }

    // }
}
.cv-container {
    padding-inline: 4rem;
    font-family: Arial, Helvetica, sans-serif;
    color: $fill;

    
    @include mobile {
            padding-inline: 0;
            padding-top: 2rem;
    }

    h3 {
            font-weight: 300;
    }

    &__lng {
            margin: 0 auto;
            display: flex;
            width: 22rem;
            justify-content: space-between;
            padding: 2rem;

            @include mobile{
                    flex-direction: column;
                    padding: 1rem;
                    height: 100%;
                    width: 100%;

            }
            @include tablet{
                flex-direction: column;
                padding: 1rem;
                height: 100%;
                width: 100%;
                padding: 0;

        }

            a {
                    border: 2px solid $fill;
                    padding: 1rem;
                    font-weight: 600;
                    border-radius: 12px;
                    text-decoration: none;
                    color: $fill;
                    cursor: pointer;

                    &:hover {
                            opacity: 0.8;
                            zoom: 1.02;
                    }
                    &:first-of-type {

                        @include mobile {
                            margin-bottom: 1rem;
                    }
                    
                    }

            }


    }

   
}
@keyframes logoIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100;
    }
}

@keyframes menuBarIn {
    0% {
        transform: scaleY(0);
    }

    100% {
        transform: none;
    }
}

@keyframes bakedAvatar {
    0% {
        opacity: 0;
        transform: scale(1.2337282615, .9459515515);
    }

    1% {
        transform: scale(1.2918607173, 1.0306209681);
    }

    3% {
        transform: scale(1.3225012657, 1.1003424939);
    }

    4% {
        transform: scale(1.3279217986, 1.1529759485);
    }

    6% {
        transform: scale(1.2780508182, 1.2053248387);
    }

    7% {
        transform: scale(1.2319227548, 1.2071001777);
    }

    9% {
        transform: scale(1.1212535786, 1.1731169371);
    }

    10% {
        transform: scale(1.065401853, 1.1432184169);
    }

    12% {
        transform: scale(1.0139650316, 1.1087997909);
    }

    14% {
        transform: scale(.9695373498, 1.0727445662);
    }

    15% {
        transform: scale(.933868851, 1.0375976325);
    }

    17% {
        transform: scale(.9078836758, 1.0054553618);
    }

    18% {
        transform: scale(.8917480812, .9779011157);
    }

    20% {
        transform: scale(.8849762281, .9559832879);
    }

    21% {
        transform: scale(.8865612095, .9402307497);
    }

    23% {
        transform: scale(.8951191068, .9306989734);
    }

    25% {
        transform: scale(.909034912, .9270391878);
    }

    26% {
        transform: scale(.9266007455, .9285826319);
    }

    28% {
        transform: scale(.94613876, .9344322277);
    }

    29% {
        transform: scale(.9661032636, .9435547022);
    }

    31% {
        transform: scale(.9851587564, .9548672291);
    }

    32% {
        transform: scale(1.0022326142, .9673139193);
    }

    34% {
        transform: scale(1.0165429605, .9799288545);
    }

    35% {
        transform: scale(1.0276037641, .9918837191);
    }

    37% {
        transform: scale(1.0352103339, 1.0025193646);
    }

    39% {
        transform: scale(1.0394091434, 1.0113617679);
    }

    40% {
        opacity: 1;
        transform: scale(1.0404563112, 1.0181237609);
    }

    42% {
        transform: scale(1.0387691198, 1.0226946098);
    }

    43% {
        transform: scale(1.034874726, 1.0251199696);
    }

    45% {
        transform: scale(1.0293597564, 1.0255749681);
    }

    46% {
        transform: scale(1.0228238592, 1.0243331865);
    }

    48% {
        transform: scale(1.0158395586, 1.0217341395);
    }

    50% {
        transform: scale(1.0089199995, 1.0181515542);
    }

    51% {
        transform: scale(1.0024954209, 1.0139643429);
    }

    53% {
        transform: scale(.9968985163, 1.009531703);
    }

    54% {
        transform: scale(.9923582464, 1.0051732943);
    }

    56% {
        transform: scale(.9890012015, 1.0011549741);
    }

    57% {
        transform: scale(.9868592659, .9976801467);
    }

    59% {
        transform: scale(.9858821306, .994886412);
    }

    60% {
        transform: scale(.9859531139, .9928469165);
    }

    62% {
        transform: scale(.9869067741, .9915755959);
    }

    64% {
        transform: scale(.9885469209, .9910353795);
    }

    65% {
        transform: scaleX(.9906638165);
    }

    67% {
        transform: scaleX(.9930495981);
    }

    68% {
        transform: scaleX(.9955112128);
    }

    70% {
        transform: scaleX(.9978804249);
    }

    71% {
        transform: scaleX(1.000020707);
    }

    73% {
        transform: scaleX(1.0018310571);
    }

    75% {
        transform: scaleX(1.0032469692);
    }

    76% {
        transform: scaleX(1.0042389329);
    }

    78% {
        transform: scaleX(1.004808938);
    }

    79% {
        transform: scaleX(1.0049855108);
    }

    81% {
        transform: scaleX(1.0048178258);
    }

    82% {
        transform: scaleX(1.0043694066);
    }

    84% {
        transform: scaleX(1.0037118824);
    }

    85% {
        transform: scaleX(1.0029191863);
    }

    87% {
        transform: scaleX(1.0020624986);
    }

    89% {
        transform: scaleX(1.0012061418);
    }

    90% {
        transform: scaleX(1.000404541);
    }

    92% {
        transform: scaleX(.9997002821);
    }

    93% {
        transform: scaleX(.9991232199);
    }

    95% {
        transform: scaleX(.9986905339);
    }

    96% {
        transform: scaleX(.9984075818);
    }

    100% {
        transform: scale(1);
    }
}