@import '../../styles/global.scss';


.flyout-menu {

        &__container {
                position: fixed;
                backface-visibility: hidden;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $fill;
                transform-origin: left bottom;
                transition: fadeIn 5s;

        }

        &__item {
                height: 100%;
                text-align: left;
                padding-left: 8rem;

                letter-spacing: 2;
                color: $text;
                cursor: pointer;
                width: 50%;
                height: 8rem;

                font-size: 59px;
                font-weight: 800;
                font-family: 'Bitter';

                @include mobile {
                        padding-left: 2rem;
                        font-size: 52px;
                        height: 5rem;
                }

                &:hover {
                        color: white;
                        zoom: 1.1;
                }


                div,
                span {

                        width: 50%;

                }
        }
}

.social-links {
        display: flex;
        justify-content: space-between;
        width: 30%;
        padding-top: 7rem;
        padding-inline: 8rem;
        padding-bottom: 1rem;
        text-align: left;


        @include mobile {
                width: 80%;
                padding-inline: 2rem;

        }
        @include tablet {
                width: 80%;
                padding-inline: 2rem;

        }


        &:before {
                display: inline-block;
                content: "";
                border-top: 0.2rem solid #7f00e0;
                width: 4rem;
                margin-top: 0.5rem;

                @include mobile {
                        display: none;

                }
        }


        img {
                width: 1.6rem;
                cursor: pointer;

                &:hover {
                        zoom: 1.1;
                }
        }
}


@keyframes fadeIn {
        0% {
                opacity: 0;
        }

        100% {
                opacity: 1;
        }
}