@import '../../styles/global.scss';


.hireme-container {
    display: flex;
    justify-content: space-between;

    &__left {
        display: flex;
        flex-direction: column;
        padding-inline: 8rem;
        width: 100%;

        @include mobile {
            display: block;
            padding-inline: 0;
       }
       @include tablet {
        display: block;
        padding-inline: 0;
   }

    }
}
.hireme-section {
    color: $text;
    text-align: left;
    width: 100%;
    @include mobile {
       flex-direction: column;
    }
    @include tablet {
        flex-direction: column;
     }

    .dot {
        @include mobile {
            display: none;
         }
    }
    a {
        color: $text;
    }
   
    
    span {
        font-size: 24px;
    }

    section {
        width: 100%;

        img {
            width: 90%;
    position: relative;
    bottom: 10rem;

          
        @include mobile {
            display: none;
     }
        }
    }

}

.contact-details {
    display: flex;
    justify-content: space-between;
    padding-top: 3rem;
    width: 100%;

    @include mobile {
      flex-direction: column;
      width: 100%;
 }
 @include tablet {
    flex-direction: column;
    width: 100%;
}
}