@import '../../styles/global.scss';


.sectionTitleContainer {
    font-family: "Bitter", sans-serif;
    text-align: left;

    padding-top: 4rem;
    padding-left: 8rem;

    color: #0f1b61;
    
    width: 100%;
    font-size: 4rem;
    transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.55s, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.55s;
    position: relative;

    @include mobile {
        padding-top: 0;
        padding-left: 0;
        font-size: 3rem;
        display: flex;
        justify-content: center;
    }
    
    @include tablet {
        padding-top: 0;
        padding-left: 0;
        font-size: 3rem;
        display: flex;
        justify-content: center;
    }

    &::before {
        content: "";
        position: absolute;
        left: 8rem;
        top: 10rem;
        height: 3px;
        background: #73bbc5;
        width: 7rem;
        transform-origin: left center;

        @include mobile {
            display: none;
        }
        
    }
}

.dot {
    color: $subtext;
    padding-inline: 0;
}